@font-face {
  font-family: 'PenneshawTitling';
  src: url('/dist/fonts/PenneshawTitling-Bold.woff2') format('woff2'),
      url('/dist/fonts/PenneshawTitling-Bold.woff') format('woff'),
      url('/dist/fonts/PenneshawTitling-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DamienText';
  src: url('/dist/fonts/DamienText-Bold.woff2') format('woff2'),
      url('/dist/fonts/DamienText-Bold.woff') format('woff'),
      url('/dist/fonts/DamienText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DamienText';
  src: url('/dist/fonts/DamienText-Medium.woff2') format('woff2'),
      url('/dist/fonts/DamienText-Medium.woff') format('woff'),
      url('/dist/fonts/DamienText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DamienText';
  src: url('/dist/fonts/DamienText-MediumItalic.woff2') format('woff2'),
      url('/dist/fonts/DamienText-MediumItalic.woff') format('woff'),
      url('/dist/fonts/DamienText-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'DamienText';
  src: url('/dist/fonts/DamienText-Regular.woff2') format('woff2'),
      url('/dist/fonts/DamienText-Regular.woff') format('woff'),
      url('/dist/fonts/DamienText-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DamienText';
  src: url('/dist/fonts/DamienText-Italic.woff2') format('woff2'),
      url('/dist/fonts/DamienText-Italic.woff') format('woff'),
      url('/dist/fonts/DamienText-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}