.main-content {
  padding-top: map-get($top-bar-height, mobile);

  @include media-breakpoint-up(lg) {
    padding-top: map-get($top-bar-height, desktop);
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    margin-right: 0.7rem;
    border-radius: 0px;
	  background-color: #2D2D3C;
	  border-color: #2D2D3C;
    color: white;
    font-weight: $weight-bold;
    padding-bottom: 0.4rem;
    font-family: $family-header;
    font-size: 0.7rem;
	&:hover{
      background-color: #2D2D3C;
      border-color: #2D2D3C;
    }
  }
}
